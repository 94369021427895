@font-face {
    font-family: 'Century Gothic Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/CenturyGothic.ttf') format('truetype');
}

@font-face {
    font-family: 'Century Gothic Regular Italic';
    font-style: italic;
    font-weight: normal;
    src: url('../static-fonts/CenturyGothicItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Century Gothic Bold';
    font-style: normal;
    font-weight: bold;
    src: url('../static-fonts/CenturyGothicBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Century Gothic Bold Italic';
    font-style: italic;
    font-weight: bold;
    src: url('../static-fonts/CenturyGothicBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Myriad Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-REGULAR.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Condensed';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-COND.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-CONDIT.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Light';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-LIGHT.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Semibold';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-SEMIBOLD.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-SEMIBOLDIT.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Bold Condensed';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-BOLDCOND.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-BOLD.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-BOLDIT.woff') format('woff');
}


@font-face {
    font-family: 'Myriad Pro Bold Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: url('../static-fonts/MYRIADPRO-BOLDCONDIT.woff') format('woff');
}